import React from 'react'
import styled from 'styled-components'

// Elements
import Plaatjie from '@ubo/plaatjie'

type GradientTypes = 'primary' | 'secondary'
interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Banner
  gradient: GradientTypes
}

const StyledBannerDefault = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: 400px;
  padding-bottom: 25px;

  @media screen and (max-width: 991px) {
    height: 250px;
  }
`

const StyledPlaatjie = styled(Plaatjie)<{ image: any }>`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;

  & img {
    object-position: 50% 70%;
  }
`

const Gradient = styled.div<{ type: GradientTypes }>`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  mix-blend-mode: multiply;
  background: ${(props) =>
    props.type === 'primary'
      ? `linear-gradient(90deg, #B4030D 0%, rgba(192, 0, 11, 0.5) 100%)`
      : `linear-gradient(90deg, #004DA7 0%, rgba(0, 77, 167, 0.5) 100%);`};
`

const Content = styled.div`
  position: relative;
  z-index: 3;

  @media screen and (min-width: 1050px) {
    margin-left: -50px;
  }

  @media screen and (max-width: 991px) {
    & h1 {
      font-size: 40px;
      line-height: 45px;
    }

    & span {
      font-size: 24px;
      line-height: 30px;
    }
  }
`

const BannerDefault: React.FC<BannerDefaultProps> = ({ fields, gradient }) => (
  <StyledBannerDefault className="text-white">
    <BannerWrapper>
      <StyledPlaatjie alt="banner" image={fields?.image} />
      <Gradient type={gradient} />
      <div className="container h-100 d-flex flex-column justify-content-end py-5">
        <Content>
          <h1 className="mb-0">{fields.title}</h1>
          <span className="font-size-huge">{fields.subtitle}</span>
        </Content>
      </div>
    </BannerWrapper>
  </StyledBannerDefault>
)

export default BannerDefault
