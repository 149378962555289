const mainTheme = {
  color: {
    primary: '#E30613',
    secondary: '#004DA7',
    grey: '#E9E9E9',
    dark: '#1C1C1C',
    green: '#25D366',
    light: '#FFFFFF',
  },

  gradient: {
    redToOpaque: `background: rgb(227,6,19);
background: -moz-linear-gradient(100deg, rgba(227,6,19,1) 0%, rgba(227,6,19,0.07) 100%);
background: -webkit-linear-gradient(100deg, rgba(227,6,19,1) 0%, rgba(227,6,19,0.07) 100%);
background: linear-gradient(100deg, rgba(227,6,19,1) 0%, rgba(227,6,19,0.07) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e30613",endColorstr="#e30613",GradientType=1);`,
    blackToOpaque: `background: rgb(0,0,0);
background: -moz-linear-gradient(100deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
background: -webkit-linear-gradient(100deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
background: linear-gradient(100deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);`,
  },

  font: {
    family: {
      primary: 'Source Sans Pro, Verdana',
    },

    size: {
      tiny: '10px',
      small: '14px',
      medium: '16px',
      big: '18px',
      large: '24px',
      larger: '30px',
      huge: '36px',
      mega: '40px',
      giga: '60px',
    },

    weight: {
      light: 300,
      regular: 400,
      semibold: 500,
      bold: 700,
      extraBold: 800,
      black: 900,
    },
  },
}

export default mainTheme
