/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'
import Linkedin from 'img/linkedin.inline.svg'
import Facebook from 'img/facebook.inline.svg'
import Phone from 'img/phone.inline.svg'

// Components
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const StyledFooter = styled.div`
  background-color: #e9e9e9;
  color: #000;
  padding: 60px 0;

  @media screen and (max-width: 991px) {
    padding: 15px 0;
  }
`

const MailLink = styled.a`
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 20px;
`

const Divider = styled.div`
  height: 0px;
  border: 2px solid #acacac;
  width: 100%;
`

const BrancheTitle = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 18px;
`

const BrancheContent = styled.div`
  & svg {
    width: 9px;
    height: 16px;
    margin-right: 10px;
  }

  & a {
    &:hover {
      text-decoration: underline;
    }
  }
`

const Img = styled.div`
  height: 50px;
  min-width: 64px;
  max-width: 110px;

  & img {
    object-fit: contain !important;
  }
`

const InfoLinks = styled.div`
  & a {
    &:hover {
      text-decoration: underline;
    }
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields, branches } =
    useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
      query footerQuery {
        fields: wpComponent(databaseId: { eq: 98 }) {
          footer {
            menu {
              mail {
                title
                url
              }
              linkedin {
                title
                url
              }
              facebook {
                title
                url
              }
              images {
                link {
                  title
                  url
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 100
                        width: 300
                        transformOptions: { grayscale: true }
                        placeholder: NONE
                      )
                    }
                  }
                }
              }
            }
          }
        }

        branches: allWpVestiging {
          nodes {
            title
            acfFields {
              address
              zipcode
              phone {
                title
                url
              }
              postbus
            }
          }
        }
      }
    `)

  return (
    <StyledFooter>
      <div className="container">
        <div className="d-flex flex-wrap py-lg-5 py-3 justify-content-md-between justify-content-center">
          <div>
            <Logo />
          </div>
          <div className="d-flex align-items-center pt-lg-0 pt-4">
            <div className="me-4">
              <MailLink href={fields?.footer?.menu?.mail?.url}>
                {fields?.footer?.menu?.mail?.title}
              </MailLink>
            </div>
            <div className="me-3">
              <a target="__blank" href={fields?.footer?.menu?.linkedin?.url}>
                <Linkedin />
              </a>
            </div>

            <div>
              <a target="__blank" href={fields?.footer?.menu?.facebook?.url}>
                <Facebook />
              </a>
            </div>
          </div>
        </div>
        <Divider />
        <div className="d-flex flex-wrap py-lg-5 py-md-3 justify-content-md-between justify-content-center">
          <div className="d-flex py-lg-0 py-3">
            {branches.nodes.map((b, index) => (
              <div className="pe-4 me-lg-5 me-3" key={index}>
                <BrancheTitle className="pb-4">
                  <div>Vestiging</div>
                  <div>{b.title}</div>
                </BrancheTitle>
                <BrancheContent>
                  <div>{b.acfFields?.address}</div>
                  <div>{b.acfFields?.zipcode}</div>
                  <div>
                    <a href={b.acfFields?.phone?.url}>
                      <Phone />
                      {b.acfFields?.phone?.title}
                    </a>
                  </div>
                  {b?.acfFields?.postbus !== '' && (
                    <ParseContent
                      className="pt-3"
                      content={b.acfFields?.postbus || ''}
                    />
                  )}
                </BrancheContent>
              </div>
            ))}
          </div>
          <div>
            <div className="d-flex align-items-center">
              {fields?.footer?.menu?.images?.map((i, index) => (
                <Img
                  className="me-4 d-flex justify-content-center align-items-center"
                  key={index}
                >
                  <a target="__blank" href={i?.link?.url || '/'}>
                    <Plaatjie image={i?.image} alt="" />
                  </a>
                </Img>
              ))}
            </div>
            <InfoLinks className="pt-4">
              <div className="text-lg-end text-center">
                <div>
                  <a
                    target="__blank"
                    href="https://admin.unit14.nl/wp-content/uploads/2021/07/Beleidsverklaring.pdf"
                  >
                    Beleidsverklaring
                  </a>
                </div>
                <div>
                  <a
                    target="__blank"
                    href="https://admin.unit14.nl/wp-content/uploads/2021/07/Unit-14-Beveiliging-BV-Algemene-Voorwaarden.pdf"
                  >
                    Algemene voorwaarden
                  </a>
                </div>
              </div>
              <div className="text-lg-end text-center">
                <Link to="/privacyverklaring">Privacyverklaring</Link>
              </div>
            </InfoLinks>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
