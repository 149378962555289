import React, { ReactElement } from 'react'
import styled from 'styled-components'

import FlexContentHandler from 'components/shared/FlexContentHandler'
import BannerDefault from 'components/elements/BannerDefault'
// @ts-ignore
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const Container = styled.div`
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  background: #fff;
  margin-top: -50px;
  z-index: 5;
  position: relative;
  margin-bottom: 30px;

  @media screen and (max-width: 991px) {
    margin-bottom: 30px;

    & .breadcrumb {
      margin-bottom: 0;
    }
  }
`

interface Props {
  // eslint-disable-next-line
  flexContent: GatsbyTypes.WpPage_Flexcontent
  prefix: string
  title: string
  location: string
  business?: boolean
  pageContext?: any
  beforeContent?: ReactElement
  afterContent?: ReactElement
}

const LayoutPage = ({
  flexContent,
  prefix,
  title,
  location,
  business = true,
  pageContext,
  beforeContent,
  afterContent,
}: Props) => (
  <>
    {flexContent.banner && (
      <BannerDefault
        gradient={`${business ? 'primary' : 'secondary'}`}
        fields={flexContent.banner}
      />
    )}

    <div className="container">
      <Container className="px-lg-5 px-3 py-lg-4 py-2">
        <Breadcrumb
          location={location}
          crumbSeparator=" > "
          crumbs={pageContext.breadcrumb.crumbs}
          crumbLabel={title}
        />

        {beforeContent}

        <FlexContentHandler
          prefix={prefix}
          fields={flexContent}
          location={location}
        />

        {afterContent}
      </Container>
    </div>
  </>
)

export default LayoutPage
